footer {
  background-color: $light-gray;
  color: $black;
  padding-top: $global-padding*3;
  padding-bottom: $global-padding*3;

  h5 {
    color: $black;
    font-weight: $global-weight-bold;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .button-group {
    margin-top: $global-margin*2;
    
    @include breakpoint(medium) {
      margin-top: 0;
    }
  }
}
