html, body {
  height: 100%;
}
//Fix for firefox
.expanded {
  min-width: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

*:focus {
  outline: none;
}

*:not(.expanded).row .row {
  width: 100%;
}

#page-wrap {
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.menu-open {
  #page-wrap {
    //overflow-y: hidden;
  }
}

#content {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding-top: 6rem;
}

#notification-box {
  background-color: #ececec;
  padding: 100px 4rem 1.5rem 1.5rem;
  border-left: 10px solid $red;
  width: 100%;

  &.warning {
    border-color: #005587;
  }

  &.info {
    border-color: $brown;
  }

  .title {
    font-weight: 700;
    margin-bottom: .25rem;
  }

  .text {
    font-size: .95rem;
    font-weight: 500;

    a {
      text-decoration: underline;
    }
  }

  .inner {
    position: relative;

    .close {
      position: absolute;
      right: -3.5rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.8rem;
      color: $secondary-color;
      cursor: pointer;
    }
  }

  & + #content {
    padding-top: 1.5rem;
  }
}

h1, h2, h3 {
  font-weight: $global-weight-bold;

  &.page-title {
    text-decoration: underline;
    margin-bottom: $global-margin*4;
    letter-spacing: .2rem;
  }
}

ol {
  padding-left: $global-padding;
}

ul {
  padding-left: $global-padding;

  &.bullets {
    padding-left: 0;

    li {
      list-style-type:none;
      padding-bottom: $global-padding/2;
      line-height: 1.4;

      .circle {
        padding-right: 0;

        &:before {
          content: '\25CF';
          font-size: 20px;
          color: $red;
        }
      }
    }
  }

  &.links {
    padding-left: 0;
    li {
      list-style-type:none;
      &:before {
        content: '>';
        font-family: $body-font-family;
        font-size: 1.15rem;
        padding-right: $global-padding/2;
      }

      a {
        text-decoration: underline;
        color: $black;

        &:hover {
          color: $red;
        }
      }
    }
  }

  &.categories {
    padding-left: 0;
    li {
      display: inline-block;
      font-family: $header-font-family;
      a {
        color: $red;
        &:hover {
          color: $dark-gray;
        }
      }
    }
  }

  &.person, &.document-list {
    padding-left: 0;
    list-style-type: none;
  }
}

a {
  &:focus, &:hover {
    color: $red;
  }
}

nav {
  display: inline;

  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: $global-margin;
    }

    a {
      &.active, &:hover {
        color: $gold;
        position: relative;
        text-decoration: underline;
      }
    }
  }
}

form {
  padding-top: $global-padding;
  padding-bottom: $global-padding;

  &#filter-form {
    padding-top: 0;
    &:last-child {
      padding-bottom: 0;
    }
  }

  .steps {
    fieldset {
      legend {
        color: $dark-blue;
        font-size: $large-font-size;
        font-family: $header-font-family;
      }
    }
  }
}

.embed-video {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    top:0;
}

.end-comma {
  &:not(:last-child) {
    &:after{
      content: ",";
    }
  }
}

p:last-child {
  margin-bottom: 0;
}

.vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

//Button styles
.button {
  position: relative;
  text-align: left;
  &:focus {
    outline: none;
  }

  &.black, .white {
    &:hover {
      border-color: $dark-gray;
      color: $dark-gray;
    }
  }

  &.close-button {
    i {
      color: $white;
      padding-left: $global-padding;
    }
  }

  span:after{
    content: '>';
    font-family: $body-font-family;
    font-size: 1.15rem;
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.oops {
  .gray-block {
    padding-top: $global-padding*4;

    img {
      margin-top: $global-padding*2;
      border-radius: 50%;
    }
  }
}

.categories-listing {
  li {
    margin-bottom: 1rem;
    a {
      &:before {
        height: 9px;
        width: 14px;
        content:'';
        background-image: url(../../assets/img/arrow.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        font-size:rem-calc(52);
        color:$gold;
        display: inline-block;
        vertical-align: middle;
        margin: 0 1rem;
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: $dark-blue;
      color: #fff;
      display: inline-block;
      width: 100%;
      padding: 1rem 0;
      &:hover {
        color: $gold;
        text-decoration: underline;
      }
    }
  }
}

a.crumb{
  color: $white;
}
a.crumb::after{
  content: ">" ;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: inline-block;
}
a.last::after{
  content: "";
}
a.crumb:focus,
a.crumb:active,
a.crumb:hover{
  color: $light-blue;  
}

.breadcrumb {
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: .9rem;
  color: $black;

  a.crumb {
    color: $black;
    font-weight: 400;

    &:hover {
      text-decoration: underline;

      &:after {
        text-decoration: none;
      }
    }
  }
}

body:not(.document-archive) {
  .breadcrumb {
    @include breakpoint(medium) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.newsletter-signup{
  padding-top: 0;
  &.frontpage{
    padding: 1rem;
    background-color: $light-gray;
  }
  div{
    padding-top: 1rem;
    padding-bottom: 1rem;
    a{
      text-decoration: underline;
    }
  }
  .newsletter-checkbox{
    margin-bottom: 0;

    &:checked{
      &:after {
        margin-bottom: 0;
      }
    }
  }
  .newsletter-label{
    display: inline;
  }
}

.date {
  font-size: .9rem;
  margin-bottom: 0.5rem;
}

.body-field {
  a {
    text-decoration: underline;
  }
}

.label {
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.sort-options-container {
  margin-bottom: 1rem;

  .label {
    display: inline-block;
    margin-right: 0.5rem;
  }

  .sort-options {
    display: inline-block;

    .sort-option {
      display: inline-block;
      margin-right: 0.5rem;
      cursor: pointer;

      .title {
        text-decoration: underline;
        margin-right: 0.25rem;
      }

      .arrow-asc, .arrow-desc {
        opacity: .3;
        font-size: 1.3rem;
        letter-spacing: -3px;

        &.active {
          opacity: 1;
        }
      }
    }
  }
}