.events-list {
  margin-bottom: 2rem;

  .no-results {
    background: $light-gray;
    padding: 0.75rem 1.5rem;
  }
}

.event {
  background: $light-gray;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0.5rem;
  display: block;

  a {
    display: block;

    span:hover {
      color: $dark-blue;
    }
  }

  .date {
    margin-bottom: 0.25rem;
    font-weight: bold;

    &:before {
      content: '';
      background: $red;
      width: .5rem;
      height: 38px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .description-wrapper {
    padding: 0;

    h4 {
      font-family: $body-font-family;
      font-weight: bold;
      padding-top: .25rem;
      font-size: 1.2rem;
      margin-bottom: .5rem;
    }

    .metadata {
      font-family: $header-font-family;
      font-size: .9rem;

      span {
        min-width: 40px;
        display: inline-block;
      }
    }
  }

  .inline-date {
    text-transform: capitalize;
  }
}

.month-header {
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }

  h4 {
    font-family: $body-font-family;
    background: $red;
    color: $white;
    text-transform: capitalize;
    padding: 0.25rem 1rem;
    font-size: rem-calc(16);
    width: 8.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
}
