.info-with-media-block {
  background-color: $light-gray;
  color: $black;
  margin-bottom: $global-margin*3;
  font-family: $header-font-family;

  .info {
    padding: $global-padding;

    .info-content {
      padding-left: $global-padding*2;
      padding-right: $global-padding;
      margin-top: 2rem;

      h1 {
        padding-bottom: $global-padding;
      }

      p {
        font-size: 1.3rem;
        line-height: 1.2;
        padding-bottom: $global-padding*2;
      }



      li {
        font-family: $body-font-family;
      }
    }
  }

  .media {
    padding-left: 0;
    padding-right: 0;
  }
}

.media {
  .media-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  img {
    width: 100%;
  }

  figcaption {
    background-color: $dark-blue;
    position: absolute;
    left: 15px;
    bottom: 15%;
    padding: $global-padding;
    color: $white;
    margin-right: 0 !important;
    min-width: 70%;

    .caption {
      font-size: $large-font-size;
    }

    .play-button {
      height: 76px;

      svg {
        fill: none;
        width: 76px;

        circle {
          stroke: $white;
        }

        polygon {
          fill: $red;
        }

        &:hover {
          circle {
            stroke: $red;
            fill: $white
          }
        }
      }
    }
  }
}

// Dark blue boxes (ie: related articles)
.blue-block {
  background-color: $dark-blue;
  color: $white;
  margin-bottom: $global-margin*3;
  padding-top: $global-padding;
  padding-bottom: $global-padding*2;


  @include breakpoint (small only){
    entry {
      padding-bottom: $global-padding*2;
    }
  }


  h3 {
    color: $white;
    margin-bottom: .5rem;
  }

  a {
    color: $white;

    &:hover{
      color: $gold;
      text-decoration: underline;
    }
  }

  img {
    padding-bottom: $global-padding;
  }

  .article {
    margin-bottom: 2rem;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}

// Gray boxes (ie: article header, registration form)
.gray-block {
  background-color: $light-gray;
  color: $black;
  margin-bottom: $global-margin;
  padding-top: $global-padding;
  padding-bottom: $global-padding*2;

  @include breakpoint(medium) {
    padding-bottom: $global-padding*4;
  }

  .bookmark-block {
    margin-bottom: 0;

    @include breakpoint(medium) {
      margin-bottom: $global-margin;
    }
  }
}

//When a bookmark is used with no background
.no-background-block {
  padding-top: $global-padding;
}

.bookmark-block {
  margin-bottom: $global-margin*2;

  .bookmark {
    position: relative;
    display: inline-block;
    right: 17px;
    background-color: $black;
    color: $white;
    padding: .5rem 1.5rem .5rem 0;
    font-size: $large-font-size;
    font-family: $header-font-family;
    border-left: 1px solid $black;

    .parallelogram {
      position: relative;
      padding: 12px;
      top: 0;
      right: 12px;

      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        height: 94%;
        width: 51%;
        background: $dark-gray;
        -webkit-transform: skew(0deg, 6deg);
        -ms-transform: skew(0deg, 6deg);
        transform: skew(0deg, 23deg);
      }
    }
  }

  .bookmark-right-content {
    margin-left: $global-margin;
    display: inline;

    nav{
      li > a.active, :hover {
        color: $red;
      }
    }
  }
}

.featured-entries {

  entry {
    padding-bottom: $global-padding*2;
  }

  img {
    margin-bottom: $global-margin;
  }

  .hr {
    margin-top: 1rem;
  }

  p {
    margin-bottom: 0;
    color: $dark-gray;

    &:hover {
      color: $red;
    }
  }
}

.matrix-block {
  margin-bottom: $global-margin*3;

  &.no-margin {
    margin-bottom: 0;
  }
}

.quote {
  margin-top: $global-margin*2;
  margin-bottom: $global-margin*3;

  blockquote {
    margin: auto;
    border-left: none;

    .quotes {
      margin-bottom: $global-margin;
    }

    p {
      font-style: italic;
      font-size: $large-font-size;
      color: $dark-blue;
    }

    cite {
      font-family: $header-font-family;

      p {
        font-style: normal;

        &.source {
          color: $dark-blue;
          font-weight: bold;
          margin-bottom: 0;
        }
        &.source-job-title {
          color: rgba($dark-blue, .5);
        }
      }

      &:before {
        content: "";
      }
    }
  }
}

.tweet {
  display: flex;
}

.hr {
  width: 45%;
  background-color: $dark-blue;
  height: 3px;
  margin-bottom: $global-margin+.5;

  &.red {
    background-color: $red;
  }

  &.short {
    width: 20%;
  }
}

.contact-persons {
  margin-top: 1rem;
  margin-bottom: 1rem;

  h2 {
    text-align: center;
  }

  & > .row {
    width: auto !important;
  }
}

.person-card {
  display: flex;
  flex-direction: column;
  padding-top: $global-padding;

  .person-card-inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $light-gray;
    padding-top: 1rem;
  }

  img {
    margin: 0 auto;
  }
  ul {
    margin-bottom: auto;
    padding-bottom: 1rem;
  }

  h3 {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    margin-bottom: $global-margin;
  }
  .chapters {
    background-color: $gold;
    margin-bottom: -1rem;
    padding: 1rem;
    color: #fff;
    a {
      color: #fff;
      &:hover {
        color: $red;
      }
      &:not(:last-child):after {
        display: inline;
        content:' / ';
      }
    }
  }
}

.chapter-card {
  margin-bottom: 1rem;

  h3 {
    margin-bottom: 0;
    a {
      color: $gold;
    }
  }
  img {
    width: 150px;
    height: 150px;
  }
  .chapter-card-inner {
    background-color: #000;
    &.row {
      margin: 0;
    }
  }
}

.person-image {
  border-radius: 50%;
}

.benefit-title {
  background-color: $dark-blue;
  color: $white;
  position: relative;
  bottom: 60px;
  padding: $global-padding/2;
  width: 85%;
  font-family: $header-font-family;
}

.image-with-caption {
  padding-left: 0;
  padding-right: 0;
  background-color: $light-gray;
  margin-bottom: $global-margin;

  figure {
    img {
      width: 100%;
    }

    figcaption {
      padding: $global-padding;
      font-size: $small-font-size;
      margin:0;
      min-width: 50%;

      .caption{
        margin-bottom: 0;
      }

      .credit {
        font-weight: $global-weight-bold;
      }
    }
  }
}

.image-gallery {
  margin-bottom: -4rem;

  @include breakpoint(medium) {
    margin-bottom: $global-margin*3;
  }

  .swiper-container {
    width: 100%;
    height: 480px;
    margin-left: auto;
    margin-right: auto;

    .swiper-slide {
      background-size: cover;
      background-position: center;
    }

    &.gallery-thumbs {
      height: 170px;
      padding: 10px 0;

      .swiper-wrapper {
        margin-left:calc(-40% - 5px);
      }

      .swiper-slide {
        width: 30%;
        height: 50%;
        opacity: 0.4;

        @include breakpoint(medium){
          width: 25%;
          height: 100%;
        }
      }

      .swiper-slide-active {
        opacity: 1;
      }
    }
  }
}


//Hide RSS feed images in the summary
.js-summary {
  img {
    display:none;
  }
}

.messenger_widget {
  position: fixed;
  bottom: 0;
  right: 0;
  #fbpf, input, .open{
    display: none;
  }
  label {
    background: url(../../assets/img/bubble.svg) no-repeat;
    font-size: 1rem;
    width: 215px;
    height: 60px;
    padding: 14px 10px 22px 10px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    right: 10px;
    z-index: 1000;
    bottom: 10px;
  }
  input:checked {
    & + #fbpf {
      display: block;
      margin-bottom: 80px;
      margin-right: 17px;
    }
    & ~ label {
      .open {
        display: inline-block;
      }
      .closed {
        display: none;
      }
    }
  }
}

.topic-categories {
  .row {
    margin: 0;
  }
}

.content-box-container {
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;

  @include breakpoint(medium) {
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-bottom: 2rem;
  }

  h2 {
    &.title {
      font-size: 1.3rem;

      @include breakpoint(medium) {
        padding-bottom: 0.5rem;
        font-size: 1.65rem;
      }
    }
  }

  .hr {
    background-color: $gold;
    height: 4px;

    @include breakpoint(medium) {
      margin-bottom: 2.5rem;
    }
  }

  &.fact {
    background-color: $light-gray;
  }

  &.highlighted {
    background-color: $dark-blue;
    color: $white;

    h2, a  {
      color: $white;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 2rem;

      li {
        a {
          &:after {
            content: '';
            border: solid $gold;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(-45deg);
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .content-box {
    margin: 0 auto;

    a {
      text-decoration: underline;
    }

    ul, ol {
      li {
        margin-bottom: 1rem;
      }
    }
  }
}