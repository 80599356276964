.registration {
  &.gray-block {
    padding-bottom: 0;
  }

  .bookmark-block {
    margin-bottom: 0;
  }

  .bookmark {
    margin-bottom: $global-margin;

    &.bar {
      background-color: $dark-blue;
      color: $white;
      padding: .7rem .5rem;
      font-size: 1rem;

      display: inline;

      &:not(:last-child) {
        border-right: 1px solid $light-gray;
      }
    }

    &.active {
      background-color: $gold;
      color: $dark-blue;
    }
  }

  .progress-bar-small {
    margin-left: $global-margin/2;
    margin-right: $global-margin/2;

    .circle {
      border-radius: 50%;
      background-color: $dark-blue;
      color: $white;
      height: 25px;
      width: 25px;
      display: inline-block;

      &.active {
        background-color: $gold;
        color: $dark-blue;
      }
    }
  }
}

form#registration {
  .required {
    &:after {
      content: "*";
      color: $red;
      padding-left: $global-padding/2;
    }
  }

  .button.black {
    &:hover {
      background-color: $black; 
      color: $white;
    }
  }
}
