.faq-summary {
  margin-top: 1rem;
  margin-bottom: 1rem;

  @include breakpoint(medium) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  a {
    text-decoration: underline;
  }
}

.faq-matrix {
  margin-bottom: 2rem;

  h2.section {
    text-transform: uppercase;
    margin-bottom: 1rem;
    padding-top: 2rem;
    font-size: 1.5rem;
    border-top: 1px solid $light-gray;

    &:first-of-type {
      border-top: none;
    }

    @include breakpoint(medium) {
      font-size: 2.25rem;
    }
  }

  .qa {
    .accordion-title {
      h3 {
        margin-bottom: 0;
      }

      &:before {
        font-size: 1.2rem;
        color: $dark-blue;
      }

      &:focus {
        background-color: transparent;
      }

      &:hover {
        background-color: $light-gray;
      }
    }

    .answer {
      a {
        text-decoration: underline;
      }
    }

    &.is-active {
      background-color: $light-gray;
    }
  }
}