.topic {
  .body-field {
    margin-bottom: 2rem;
  }

  @include breakpoint(medium) {
    .main-content-column {
      .matrix-block {
        max-width: 100%;
        flex-basis: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .block {
    .row {
      &.align-center {
        & + .matrix-block.html {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}