
/* Mobile */
@media only screen and (max-width: 767px) {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    tbody {
      display: table;
      width: 100%;
    }
  }

  table td, table th {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
  }
}
