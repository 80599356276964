.article-title {
  h1 {
    margin-top:1rem;
    @include breakpoint(medium) {
      margin-bottom: $global-margin*2;
    }
  }
  .hr {
    width: 10%;
    margin: 0 auto $global-margin;

    @include breakpoint(medium) {
      margin: 0 auto $global-margin*2;
    }
  }
}

.article-info {
  .person {
    @include breakpoint(medium) {
      text-align: right;
    }
  }

  img {
    margin-top: $global-margin/2;
    margin-bottom: $global-margin/2;

    @include breakpoint(medium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .dates {
    @include breakpoint(medium) {
      text-align: left;
    }
  }
  p {
    margin-bottom: 0;
    font-family: $header-font-family;

    .time {
      padding-left: $global-padding;
    }
  }
}

.article-summary {
  font-size: 1.5rem;
  margin-top: $global-margin*2;
  margin-bottom: $global-margin*3;

  ul {
    list-style-type: disc;
  }
}

.matrix-block.html, .article-summary {
  a {
    text-decoration: underline;
    &:hover{
      color: $red;
    }
  }
}

.sidebar {
  .person-card {
    padding: 0;
  }
}

.person-card:last-child{
  margin-bottom: 1rem;
}

