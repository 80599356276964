//  Foundation for Sites Settings
//  -----------------------------

@import 'util/util';

// 1. Global
// ---------
//PF colors
$dark-blue:   #133243;
$darkest-blue:#0E2531;
$light-blue:  #C2CEE5;
$red:         #E8282C; //#EB5757;
$light-red:   #f88189;
$light-gray:  #E9E5DD;
$dark-gray:   #4A4A4A;
$gold:        #EAC17F;
$brown:       #B69C4E;
$black:       #0a0a0a;
$white:       #fefefe;
$primary-color: $dark-blue;

$global-width: rem-calc(1200);
$global-font-size: 100%;
$global-lineheight: 1.5;
$secondary-color: #777;
$success-color: #3adb76;
$warning-color: #ffae00;
$alert-color: #ec5840;
$medium-gray: #cacaca;
$body-background: $white;
$body-font-color: $dark-blue;
$body-font-family: 'Roboto', sans-serif;

$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-text-direction: ltr;
$rem-base: 16px;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$breakpoint-classes: (small medium large);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: 30px;
$grid-column-align-edge: true;
$block-grid-max: 8;

// 4. Base Typography
// ------------------
$header-font-family: 'Roboto Condensed', sans-serif;
$header-font-weight: $global-weight-normal;
$header-font-style: normal;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$header-color: $dark-blue;
$header-lineheight: 1.2;
$header-margin-bottom: 1rem;
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$large-font-size: 110%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 1.4;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$code-color: $black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
$code-border: 1px solid $medium-gray;
$code-padding: rem-calc(2 5 1);
$anchor-color: $primary-color;
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$list-lineheight: 1.5;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 0;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;
$cite-font-size: rem-calc(13);
$cite-color: $dark-gray;
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

// 11. Button
// ----------

$button-padding: 0.85em 1em;
$button-margin: 0 0 $global-margin 0;
$button-fill: hollow;
$button-background: $primary-color;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-color: #fff;
$button-color-alt: #000;
$button-radius: $global-radius;
$button-sizes: (
  tiny: 0.6rem,
  small: 0.75rem,
  default: 0.9rem,
  large: 1.25rem,
);
$button-opacity-disabled: 0.25;

// 12. Button Group
// ----------------

$buttongroup-margin: 1rem;
$buttongroup-spacing: $global-margin;;
$buttongroup-child-selector: '.button';
$buttongroup-expand-max: 6;

//Custom Settings
$anchor-color-hover: $secondary-color;
$header-styles: (
  'small': (
    'h1': ('fs': 24, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
    'h2': ('fs': 20, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom/2),
    'h3': ('fs': 19, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
    'h4': ('fs': 18, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
    'h5': ('fs': 17, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
    'h6': ('fs': 16, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom)
  ),
  'medium': (
    'h1': ('fs': 36, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
    'h2': ('fs': 22, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom/2),
    'h3': ('fs': 20, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
    'h4': ('fs': 20, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
    'h5': ('fs': 18, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
    'h6': ('fs': 16, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom)
  )
);

$button-palette: (
    black: $black,
    red: $red,
    white: $white
) !default;
