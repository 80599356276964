#page-helpfulness-form-wrapper {
  padding: 2rem 0;
  background-color: rgba($light-gray, .5);

  &.success {
    background-color: $success-color;
  }

  h4 {
    margin-bottom: 0;
  }

  form {
    padding: 0;

    .first-step {
      .wf-group-heading,
      .wf-radio {
        display: inline-block;
      }

      .wf-group-heading {
        @include breakpoint(small only){
          width: 100%;
        }
        @include breakpoint(medium) {
          margin-right: 6rem;
        }

        .wf-label {
          margin-bottom: 0;
        }
      }

      .wf-radio {
        input {
          display: none;
        }

        label {
          border: 2px solid $black;
          padding: .3rem 1.35rem;
          margin-right: .5rem;
        }
      }
    }

    .second-step,
    input[type="submit"],
    .cancel-button {
      display: none;
    }

    .wf-group-heading {
      .wf-label {
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }
    }

    .wf-group {
      &.label-hidden {
        .wf-label {
          display: none;
        }
      }

      &.message {
        textarea {
          display: block;
          margin-bottom: 0.25rem;
          min-height: 100px;
          max-width: 500px;
        }

        .rchars {
          display: block;
        }
      }
    }

    .wf-checkbox {
      overflow: hidden;

      .wf-field {
        float: left;
      }
    }

    input[type="submit"],
    .cancel-button {
      float:left;
      border: 2px solid $black;
      padding: .3rem 1.35rem;
      font-size: 1rem;
      line-height: 1.5;
      cursor: pointer;
      margin-top: 1rem;
    }

    input[type="submit"] {
      background-color: $dark-blue;
      margin-left: 1rem;
      color: $white;
    }

    input[name="name"] {
      display: none;
    }
  }
}

.select-container {
  &.inline-label {
    display: inline-block;
    width: 100%;
    text-align: right;

    label,
    select {
      display: inline-block;
    }

    label {
      font-weight: 500;
      margin-right: .5rem;
    }

    select {
      max-width: 150px;
    }
  }
}

#events-filter-form {
  select {
    margin-bottom: 0;
  }
}

