.entries-list {
  .entry {
    border-bottom: 1px solid $light-gray;
    padding-top: $global-padding*2;
    padding-bottom: $global-padding*2;

    &:first-child{
      border-top: 1px solid $light-gray;
    }
  }
}

.get-more-entries {
  padding-top: $global-padding*3;
  padding-bottom: $global-padding*2;

  .loading-spinner{
    display: none;
  }

  img {
    width: 7%;
  }
}