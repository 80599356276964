.categories {
  .category-fieldset {
    fieldset {
      margin-bottom: $global-margin;

      .legend {
        legend {
          border-bottom: 1px solid $light-blue;
          width: 100%;
          margin-bottom: 1rem;
        }
      }

      .checkbox-wrapper {
        .category-link {
          padding-bottom: $global-padding;
        }

        .label-container {
          padding-left: 0;
        }
      }

      &.year {
        .checkbox-wrapper {
          columns: 2;
        }
      }

      label {
        cursor: pointer;
      }
    }
  }

  .input-group-field {
    border-color: $light-blue;
    box-shadow: none;

    &:focus {
      border-color: scale-color($light-blue, $lightness: -15%);
    }
  }

  .input-group-button {
    background-color: $gold;

    .button {
      border: none;
      padding: 0.85em 2em;
    }
  }

  i {
    color: $dark-blue;
    padding-right: $global-padding;
  }
}

input[type='checkbox']{
  -webkit-appearance: none;
  appearance: none;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  border: 1px solid black;
  position: relative;
  margin-bottom: 0.25rem;
  top: 3px;

  &:focus{
    outline: none;
  }

  &:checked{
    border: none;

    &:after {
      display: inline-block;
      background: url(../img/x.svg) no-repeat;
      content: '';
      background-size: contain;
      width: 1rem;
      height: 1rem;
      margin-bottom: 0.25rem;
    }
  }
}

.article-categories {
  display: none;
  position: fixed;
  background-color: $white;
  width: 100%;

  .close-categories {
    height: 24px;
  }
}

.show-article-categories {
  margin-bottom: 3px;
}

.topic-categories {

  ul {
    padding-left:0;
    list-style-type: none;
  }
}