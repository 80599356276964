.collapsible-section {
  .accordion-item {
    .accordion-title {
      h3 {
        margin-bottom: 0;
      }

      &:before {
        font-size: 1.2rem;
        color: $dark-blue;
      }

      &:focus {
        background-color: transparent;
      }

      @include breakpoint(medium) {
        &:hover {
          background-color: $light-gray;
        }
      }
    }

    .accordion-content {
      a {
        text-decoration: underline;
      }
    }

    &.is-active {
      background-color: $light-gray;
    }
  }

  &.no-border-bottom {
    .accordion-item {
      .accordion-title,
      .accordion-content {
        border-bottom: 0;
      }
    }
  }
}