.js-document-results {
  .success-results {
    width: 100%;

    .details {
      display: flex;

      .label {
        padding-right: .25rem;
      }

      .button {
        margin-left: auto;
        padding: 0.3em 0.85em;
        text-align: center;
        line-height: 1.2;
      }
    }
  }

  .no-results {
    display: flex;

    h3 {
      padding-right: .25rem;
    }

    .button {
      margin-left: auto;
      padding: 0.3em 0.85em;
      text-align: center;
      line-height: 1.2;
    }
  }
}

.document-filters-container {
  .js-show-document-filters {
    position: relative;
    padding: 0.65rem 2.5rem 0.65rem 1rem;
    font-weight: 500;
    font-size: 1.1rem;

    &::before {
      position: absolute;
      top: 50%;
      margin-top: -0.5rem;
      content: '+';
      right: 1rem;
    }
  }

  @include breakpoint(small only){
    #filter-form {
      display: none;
    }
  }

  &.filters-open {
    .js-show-document-filters {
      &::before {
        content: '\2013';
      }
    }

    #filter-form {
      display: block;
    }
  }
}

.document-list {
  border-top: 1px solid $light-blue;

  li {
    border-bottom: 1px solid $light-blue;
    font-size: .95rem;
    padding: 0.35rem 0.5rem;
    display: flex;
    align-items: center;

    a {
      display: inline-block;
      max-width: 90%;
    }

    span {
      text-transform: uppercase;
      color: $light-blue;
      font-size: .8rem;
      font-weight: 500;
      margin-left: auto;
    }

    &:hover {
      background-color: $light-gray;
    }
  }
}