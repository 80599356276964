.block {
  //margin-bottom: $global-margin*3;
}
.icon-row{
  margin-top: 1rem;
  margin-bottom: 1rem;
  a:focus{
    border:1px solid $dark-gray;
  }
  .frontpage-icon{
    background-repeat: no-repeat;
    background-size: cover;
    max-width: calc(100% - 2rem);
    margin-bottom: 1rem;
    padding:0;

    margin-left: 1rem;
    margin-right: 1rem;
    h2{
      margin-top: auto;
      margin-bottom: auto;
      padding-left: 1rem;
    }
      .icon-block{
        width: 100%;
  
        background-color: $light-gray;
        img{
          margin-right: -1rem;
          padding: 1rem;
          height: auto;
        }
      }
    
    
   .row{
     margin-left: auto;
     margin-right: auto;
   }
    @include breakpoint(medium){
      max-width: calc(50% - 2rem);
      margin-bottom: 2rem;
    }
    @include breakpoint(large){
      max-width: calc(50% - 2rem);
    }
  }
}
.divider{
  width:100%;
  &.hr{
    margin-bottom: 1rem;
  }
}
.homepage{
  .featured{
    >div{
      margin-bottom: 3rem;
    }
    .buttonCont{
      margin-top: 1rem;
      text-align: center;
    }
  }
}
