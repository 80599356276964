header {
  background-color: $dark-blue;
  margin-bottom: $global-margin;
  display: block;

  .top-bar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: $dark-blue;
    display: flex;
  }

  .left-content {
    padding: $global-padding 0 $global-padding $global-padding;

    nav {
      display: none;
      padding-left: $global-padding*3;

      @include breakpoint(1700) {
        display: inline-block;

        ul {
          li {
            &.show-for-mobile {
              display: none;
            }

            &:not(:last-child) {
              margin-right: 1.25rem;
            }

            a {
              &.active {
                color: $white;
                text-decoration: none;

                &:hover {
                  color: $gold;
                  text-decoration: underline;
                }
              }
            }  
          }
        }
      }
    }
  }
  &.row .columns.row {
    //display: none;
    @include breakpoint(large) {
      display: flex;
    }
  }
  a {
    color: $white;

    &:focus{
      color: $gold;
    }
  }

  .right-content {
    padding-right: 0;
    font-family: $header-font-family;

    .large-only {
      display: none;

      @include breakpoint(1700) {
        display: inline-block;
      }
    }

    .search {
      background-color: $darkest-blue;
      //height: 99%;
      color: $white;
      position: relative;

      img {
        padding-left: $global-padding;
        cursor: pointer;
      }
      .search_icon {
        background: url(../../assets/img/magnifying_glass.png) no-repeat;
        background: url(../../assets/img/magnifying_glass.svg) no-repeat;
        background-size: contain;
        background-position: center;
        height: 20px;
        width: 20px;
        margin-left: 10%;
        overflow: hidden;
        text-indent: 1000px;
        border: 0;
        cursor: pointer;
      }

      form {
        display: flex;
        align-items: center;
        & > input {
          display: inline-block;
          vertical-align: middle;
        }
      }
      input.search_input {
        display: inline-block;
        width: 70%;
      }
    }

    .min-side {
      background-color: $gold;
      color: $black;
      text-align: center;
      padding-left: $global-padding;
      padding-right: $global-padding;

      a {
        color: $black;
        &:hover {
          color: $white;
        }
      }
    }

    // Burger menu on small screens
    .burger-wrapper {
      cursor: pointer;
      padding-right: $global-padding;
      height: 100%;
      margin-left: auto;

      &.small-only {
        @include breakpoint(1700) {
          display: none;
        }
      }

      .burger-icon {
        @include burger(25px, 3px, 5px, $white);
        margin-top: 0;
      }

      &.is-active {
        .burger-icon {
          @include burger-to-cross;
        }
      }
    }
  }
}

.overlay-placeholder {
  width: 100%;

  .overlay-menu {
    background-color: $dark-blue;
    //min-height: calc(100vh - 90px);
    width: 100%;
    position: fixed;
    top: 90px;
    bottom: 0;
    z-index: 5;
    display: none;
    overflow-y: auto;

    .mobile-search {
      background-color: $darkest-blue;
      .search_icon {
        background: url(../../assets/img/magnifying_glass.png) no-repeat;
        background: url(../../assets/img/magnifying_glass.svg) no-repeat;
        background-size: contain;
        background-position: center;
        height: 20px;
        width: 20px;
        margin-left: 10%;
        overflow: hidden;
        text-indent: 1000px;
        border: 0;
        cursor: pointer;
      }
      .image-wrapper {
        position: absolute;
        top: 23px;
        right: 0;
      }
    }

    ul {
      //padding-top: $global-padding*2;
      padding-top: 0;
      padding-bottom: $global-padding*2;
      padding-left: 0;
      font-size: 1.5rem;

      li {
        a {
          display: block;
          padding-top: $global-padding/4;
          padding-bottom: $global-padding/4;
        }
      }
    }
    .min-side {
      background-color: $gold;
      color: $black;
      text-align: center;
      padding: calc(1rem - 2.5px);
      //padding-left: $global-padding;
      //padding-right: $global-padding;
      margin-top: 0;
      a {
        color: $black;
        &:hover {
          color: $white;
        }
      }
    }
  }
}

.menu-open {
  #content {
    overflow: hidden;
  }
}

//Basic search form; text input
input[type='search']{
  margin: 0;
  border: none;
  background-color: $darkest-blue;
  color: $white;
  padding-left: $global-padding;

  &:focus {
    background-color: $darkest-blue;
    border: none;
    box-shadow: none;
    color: $white;
  }
}
